.onetoone {
    display: flexbox;
    width: 100%;
    height: 92vh;
    background-color: white;
}

.onetoone .header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    border-bottom: 1px #f1f1f1 solid;
}

.onetoone .header .title {
    color: #414141;
    font-weight: bold;
    text-align: center;
}

input[type='search'] {
    width: 95%;
    height: 42px;
    border-radius: 50px;
    margin-left: 15px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 10px;
    outline: none !important;
    border: 2px #c0c0c0 solid;
    margin-top: 5px;
    margin-bottom: 10px;
}

input[type='search']:focus {
    outline: none !important;
    border: 2px solid var(--color-primary);
    box-shadow: 0 0 2.3px var(--color-primary);
}
