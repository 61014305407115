.screen {
    height: 100vh;
    background-color: white;
}

.container {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 120px;
}

.containerButton {
    width: 100%;
    display: flex;
    justify-content: center;
}

.textResult {
    font-size: 15px;
    font-weight: 600;
    color: var(--color-primary);
    margin-top: 10px;
    text-align: center;
}